
export default class FadeModArticle {

    constructor(elements){
        this.elements = elements;
    }

    calculateOpacity() {
        // https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
        this.elements.forEach(function(el,index) {

            let bounding = el.getBoundingClientRect(),
                opacityValue = (1 - (bounding.y / document.body.clientHeight)) * 2 ;

            if(opacityValue > 1) {opacityValue = 1; }
            if(opacityValue < 0) {opacityValue = 0; }

            el.style.opacity = opacityValue;
        });
    }
}
