export default class ScrollButton {

    constructor() {

        this.scrollbutton = document.querySelector('#scrollbutton');

        this.scrollbutton.addEventListener('click', function(){

            let scrollY = document.documentElement.scrollTop,
                h = document.body.clientHeight,
                dH = document.documentElement.scrollHeight;


            if(scrollY < dH-h) {
                window.scrollTo({
                    top: scrollY + h,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }
        });

        window.addEventListener('scroll', function(){

            let scrollY = document.documentElement.scrollTop,
                h = document.body.clientHeight,
                dH = document.documentElement.scrollHeight;

            if(scrollY >= dH-h) {
                this.scrollbutton.classList.add('scrollup');
            }
            else {
                this.scrollbutton.classList.remove('scrollup');
            }
        });
    }

}
