import '../scss/app.scss'
import DebugInfos from "./libs/DebugInfos";
import FadeModArticle from "./libs/FadeModArticle";
import Clients from './libs/Clients';
import ScrollButton from './libs/ScrollButton';
import FormErrors from './libs/FormErrors';
import Sm from './libs/ScrollButton';
import smoothscroll from 'smoothscroll-polyfill';
import Swiper, {Navigation} from 'swiper';
Swiper.use([Navigation]);

(() => {
    const
        d = document.body,
        initSlider = (id) => {

        //console.log(`.rsce-slider.${ id }`);
            const
                container = d.querySelector(`.rsce-slider.${ id }`),
                isValidContainer = (container instanceof HTMLElement),
                onSliderUpdate = index => {
                    // console.log(`${ id } slider ->`, index)
                }

            //console.log(container);
            if (!isValidContainer) {
                return
            }

            switch (id) {
                case 'standard': return new SliderStandard(container, index => { onSliderUpdate(index) })
            }
        },
        initDevUtils = () => {
            const isDevMode = (window.location.href.indexOf(`#devmode`) > -1)

            if (isDevMode) {
                // Show screen size label at top center of viewport.
                d.parentNode.classList.add('devmode')

                // Draw grid lines.
                const modules = [
                    ...d.querySelectorAll('.module'),
                    d.querySelector('header .overlay'),
                    d.querySelector('#emergency-service > div'),
                ]
                modules.map(module => new ModuleGrid(module))
            }
        },
        initYoutubeDSGVO = () => {

            const youtubeVideos = d.querySelectorAll(`.rsce-youtube-dsgvo`);

            for (let i = 0; i < youtubeVideos.length; i++) {
                youtubeVideos[i].addEventListener("click", function() {
                    this.classList.add("showIframe");
                    this.innerHTML = `<iframe src="https://www.youtube-nocookie.com/embed/${this.dataset.youtubeId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                });
            }

        },
        initDebugInfos = () => {

            const info = new DebugInfos();

            window.addEventListener('resize', function(){
                info.showValues();
            });
            window.addEventListener('scroll', function(){
                info.showValues();
            });

        },
        initFadeModArticle = () => {

            let modArticles = d.querySelectorAll(`.mod_article`);
                modArticles = new FadeModArticle(modArticles);

            window.addEventListener('resize', function(){
                modArticles.calculateOpacity();
            });
            window.addEventListener('scroll', function(){
                modArticles.calculateOpacity();
            });

        },
        initClients = (showAnimation) => {

            const
                container = d.querySelector(`#clients`),
                isValidContainer = (container instanceof HTMLElement);

            if (!isValidContainer) {
                return
            }

            // new Clients(showAnimation);
            new Clients(true);

        },
        initScrollButton = () => {
            new ScrollButton;
        },
        initAutoScroll = () => {
            const el = document.querySelector('body');
            let scrollTimer;

            if(el.className === 'frontpage' || document.documentElement.scrollTop > 200){
                return;
            }
            else {
                scrollTimer = setTimeout(()=>{
                    document.querySelector('#scrollbutton').click();
                },1000);

                window.addEventListener('scroll', dontScroll);
            }

            function dontScroll(){
                clearTimeout(scrollTimer);
                window.removeEventListener("scroll", dontScroll);
            }


        },
        initSmoothScroll = () => {
            smoothscroll.polyfill();
        },
        initSwiper = () => {
            const swiper = new Swiper('.swiper-container',{
                // Optional parameters
                direction: 'horizontal',
                autoHeight: true,
                loop: true,
                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        },
        initFormErrors = () => {
            new FormErrors();
        }


    // initSlider('standard')
    //initDevUtils()
    initYoutubeDSGVO()
    //initDebugInfos()
    initFadeModArticle()
    initClients()
    initScrollButton()
    initAutoScroll()
    initSmoothScroll()
    initSwiper()
    initFormErrors()

})()
