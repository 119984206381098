export default class Clients {


    constructor(showAnimation) {

console.log(showAnimation);
        this.clientsContainer = document.querySelector('#clients');


        this.xMax = this.clientsContainer.offsetWidth;
        this.yMax = this.clientsContainer.offsetHeight;

        if(showAnimation == true || showAnimation == undefined) {
            this.doAnimation();
            this.moveLeft();
        }
        else if (showAnimation == false){
            console.log("nöö");
        }

    }


    doAnimation() {

        let clients = document.querySelectorAll('.client');

        for (let i = 0; i < clients.length; i++) {

            if(window.getComputedStyle(clients[i]).getPropertyValue("opacity") == 0 ) {

                if(clients[i].classList.contains("fadeInOutFast") ||
                    clients[i].classList.contains("fadeInOutNormal") ||
                    clients[i].classList.contains("fadeInOutSlow")){
                    clients[i].classList.remove('fadeInOutFast','fadeInOutNormal','fadeInOutSlow');
                    break;
                }
                else{
                    clients[i].style.left = Math.floor(Math.random() * (this.xMax + 250)) + "px";
                    clients[i].style.top  = Math.floor(Math.random() * this.yMax) + "px";
                    let fontsize = (Math.floor(Math.random() * (40 - 14 + 1)) + 14) / 16 ;
                    clients[i].style.fontSize  = fontsize + "rem";

                    let Timings = ["fadeInOutFast", "fadeInOutNormal", "fadeInOutSlow"];
                    clients[i].classList.add(Timings[Math.floor(Math.random() * 3)]);
                }
            }

        }

       setTimeout(()=>{
           this.doAnimation();
       },500);
    }

    moveLeft(){
        let clients = document.querySelectorAll('.client');
        let subtracValue;

        for (let i = 0; i < clients.length; i++) {
            if(clients[i].classList.contains("fadeInOutFast")){
                subtracValue = 5;
            }
            if(clients[i].classList.contains("fadeInOutNormal")){
                subtracValue = 3;
            }
            if(clients[i].classList.contains("fadeInOutSlow")){
                subtracValue = 1;
            }
            let newX = parseInt(clients[i].style.left) - subtracValue;
            clients[i].style.left = newX + 'px';
        }

        setTimeout(()=>{
            this.moveLeft();
        },25);
    }


}
