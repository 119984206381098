export default class FormErrors {


    constructor() {

        const errors = document.querySelectorAll('p.error');


        if (errors.length > 0) {
            console.log("There are Errors");

            let box = errors[0].getBoundingClientRect();


            window.scrollTo({
                top: pageYOffset + box.top - 50,
                left: 0,
                behavior: 'smooth'
            });


        }

    }
}
