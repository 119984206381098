
export default class DebugInfos {

    constructor(){
            this.debuginfo = document.createElement("div");
            this.debuginfo.style.cssText = "" +
                "padding: 5px; " +
                "color: #00ff00; " +
                "font-size: 10px; " +
                "font-family: Sans-serif; " +
                "position: fixed; " +
                "z-index: 10000; " +
                "top: 0; " +
                "background: black; ";

            document.body.appendChild(this.debuginfo);
            this.showValues();
    }

    showValues() {
        let info,
            scrollY = document.documentElement.scrollTop,
            w = document.body.clientWidth,
            h = document.body.clientHeight,
            dH = document.documentElement.scrollHeight;
            ;
            info = `${w} x height: ${h} | yScroll: ${scrollY} | dH: ${dH}`;

        this.debuginfo.innerText = info;
    }

}
